import {
	EFxIdSdkAdapterInfoSocialCapability,
	EFxIdSdkGender,
	FxIdSdkAdapterSocialSettingDefault,
	FxIdSdkBaseAdapter,
	IExportedFxIdSdkMethod,
	IFxIdSdkAdapterBuyProductRequest,
	IFxIdSdkAdapterBuyProductResponse,
	IFxIdSdkAdapterGetFriendsRequest,
	IFxIdSdkAdapterInfoSocial,
	IFxIdSdkAdapterInviteFriendsRequest,
	IFxIdSdkAdapterSocialSettings,
	IFxIdSdkAdapterStatEventRequest,
	IFxIdSdkAdapterStatInitializeRequest,
	IFxIdSdkGetFriendsResult,
	IFxIdSdkGetFriendsResultFriend,
	IFxIdSdkInviteFriendsResult
} from "./FxIdSdkBaseAdapter";
import { deferred } from "./FxIdSdkUtils";
import OpenApiClient from "../Api/OpenApiClient";
import {
	FxIdApplicationStoreCreatePaymentHandlerEmbeddingType,
	FxIdDomainStoreEnumsSupportedWebPublishingPlatform,
	FxGamesLibWebPublicDataBase,
	type FxIdWebFeaturesStoreCreatePaymentRequest
} from "../Api/gen";
import { sharingHandlersStore, userStore } from "../Stores";
import i18next from "i18next";
import { chunk, max } from "lodash";
import { useIsMobile } from "../Screens/WebPlayer/Hooks";
import { openInfoModal } from "../Components/Modals/InfoModal";
import { getAuthToken } from "@app/Api/Auth";

type OkApiCallback = (
	status: "ok" | "error",
	data: unknown | null,
	error: unknown | null
) => void | PromiseLike<unknown>;

type IOkGetUserInfoResult = {
	uid: string;
	first_name: string;
	last_name: string;
	gender: string;
	pic224x224: string;
};

export class FxIdSdkAdapterForOk extends FxIdSdkBaseAdapter {
	private buyProductResolve?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;
	private buyProductReject?: (
		value: PromiseLike<IFxIdSdkAdapterBuyProductResponse> | IFxIdSdkAdapterBuyProductResponse
	) => void;

	private loadAdResolve?: (value: PromiseLike<string> | string) => void;
	private loadAdReject?: (value: PromiseLike<string> | string) => void;
	private showAdResolve?: (value: PromiseLike<string> | string) => void;
	private showAdReject?: (value: PromiseLike<string> | string) => void;
	private getPageInfoResolve?: (value: PromiseLike<string> | string) => void | Promise<void>;
	private getPageInfoReject?: (value: PromiseLike<string> | string) => void;

	private showNotificationResolve?: (value: PromiseLike<string> | string) => void;
	private showNotificationReject?: (value: PromiseLike<string> | string) => void;

	private _adAvailable = false;

	private _TOP_PANEL_HEIGHT = 48;
	private _innerHeight = 0;
	private _PAGE_INFO_REFRESH_INTERVAL_MS = 5000;

	constructor(
		protected exportedSdk: IExportedFxIdSdkMethod,
		protected game: string,
		protected config: FxGamesLibWebPublicDataBase
	) {
		super(exportedSdk);

		(window as any).API_callback = this.ApiCallback.bind(this);

		this.getPageInfoResolve = async (data) => {
			const dataStr = typeof data === "string" ? data : await data;
			try {
				const pageData: { innerWidth: number; innerHeight: number } = JSON.parse(dataStr);
				if (this._innerHeight !== pageData.innerHeight) {
					log.info("Updating window size");
					this._innerHeight = pageData.innerHeight;
					FAPI.UI.setWindowSize(pageData.innerWidth, pageData.innerHeight);
					FAPI.UI.scrollTo(0, this._TOP_PANEL_HEIGHT);
				}
			} catch (error) {
				log.error("Error while parsing getPageInfo response!", error);
			}
		};
	}

	RegisterShareHandlers(): Promise<void> {
		// NOTE: На мобильном ВК окно showInvite может не вызваться если нет друзей и просто ничего не покажется,
		// придет колбек в API_Callback (ниже)
		// TODO: Надо будет делать спец окно "нет друзей для пришлашения"
		// https://apiok.ru/dev/sdk/js/ui.showInvite/

		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isMobile = useIsMobile();

		if (!isMobile) {
			sharingHandlersStore.getState().enableShareOk(() => {
				// FAPI.UI.postMediatopic(
				// 	{
				// 		media: [
				// 			{
				// 				type: "text",
				// 				text: i18next.t(`seo.${this.game}.description`)
				// 			},
				// 			{
				// 				type: "link",
				// 				url: `https://ok.ru/game/${this.config.PlatformData.Odnoklassniki!.AppId}`
				// 			}
				// 		]
				// 	},
				// 	false
				// );

				const text = i18next.t(`seo.${this.game}.invite`);

				log.info("Sharing with text %s", text);

				const callback = (status: unknown, result: string[], data: unknown) => {
					log.info("Friends.get callback. Status: %o, result: %o, data: %o", status, result, data);
					if (result.length > 0) {
						FAPI.UI.showInvite(text, "", result[0]);
						// в случае успеха возвращает в API_callback третьим параметром строку, в которой через запятую указаны id приглашенных друзей
					} else {
						FAPI.UI.showInvite(text);
					}
				};
				FAPI.Client.call({ method: "friends.get" }, callback);
			});
		} else {
			log.info("OK invite currently disabled on mobile");
		}
		return Promise.resolve();
	}

	SocialSettings(): Promise<IFxIdSdkAdapterSocialSettings> {
		return Promise.resolve(FxIdSdkAdapterSocialSettingDefault);
	}

	ApiCallback(method: string | "showPayment", result: "ok" | "cancel" | "error", data: string) {
		log.info(`Api callback: method: %s, result: %s, data: %o`, method, result, data);
		switch (method) {
			case "showPayment":
				if (result !== "ok") {
					this.buyProductReject?.call(this, { error: JSON.stringify({ result: result, data: data }) });
				} else {
					this.buyProductResolve?.call(this, {});
				}
				return;
			case "loadAd":
				if (result !== "ok") {
					this._adAvailable = false;
					this.loadAdReject?.call(this, data);
				} else {
					this._adAvailable = true;
					window.FxIdSdk!.DispatchAdsVideoAvailable();
					this.loadAdResolve?.call(this, data);
				}
				return;
			case "showAd":
				if (result === "ok") {
					window.FxIdSdk!.DispatchAdsFinished();
				} else {
					window.FxIdSdk!.DispatchAdsFailed();
				}
				break;
			case "showLoadedAd":
				if (result !== "ok") {
					if (data === "skip") {
						window.FxIdSdk!.DispatchAdsSkipped();
					} else {
						window.FxIdSdk!.DispatchAdsFailed();
					}
					this.showAdReject?.call(this, data);
				} else {
					// Просим загрузить еще
					FAPI.UI.loadAd();

					window.FxIdSdk!.DispatchAdsFinished();
					this.showAdResolve?.call(this, data);
				}
				return;
			case "getPageInfo":
				if (result !== "ok") {
					this.getPageInfoReject?.call(this, data);
				} else {
					void this.getPageInfoResolve?.call(this, data);
				}
				return;
			case "showNotification":
				if (result !== "ok") {
					this.showNotificationReject?.call(this, data);
				} else {
					this.showNotificationResolve?.call(this, data);
				}
				return;
			case "showInvite":
				log.info("Show invite received result: %o", method);
				if (result !== "ok") {
					openInfoModal("Нет доступных для приглашения друзей");
				}
				return;

			default:
				log.info("Unsupported method in API callback: %o", method);
		}
	}

	async BuyProduct(request: IFxIdSdkAdapterBuyProductRequest): Promise<IFxIdSdkAdapterBuyProductResponse> {
		const paymentRequest: FxIdWebFeaturesStoreCreatePaymentRequest = {
			Token: getAuthToken()!,
			Game: this.game,
			Sku: request.sku,
			WebPublishingPlatform: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki,
			EmbeddingType: FxIdApplicationStoreCreatePaymentHandlerEmbeddingType.Embed,
			ProductDescriptionHint: request.productDescriptionHint,
			ProductNameHint: request.productNameHint
		};

		if (request.developerPayload != null) {
			paymentRequest.DeveloperPayload = {
				MerchantDeveloperPayload: request.developerPayload
			};
		}

		const createPaymentResult = await OpenApiClient.Store.fxIdWebFeaturesStoreCreatePaymentEndpoint(paymentRequest);

		log.info("Received result from server: %o", createPaymentResult);

		const { promise, resolve, reject } = deferred<IFxIdSdkAdapterBuyProductResponse>();
		this.buyProductResolve = (data: any) => {
			resolve({
				...data,
				transactionId: createPaymentResult.TransactionId,
				stats: {
					currency: createPaymentResult.OrderProduct.Currency ?? "",
					currencyAmount: createPaymentResult.OrderProduct.Price ?? 0,
					usdAmount: createPaymentResult.OrderProduct.UsdPrice ?? 0
				}
			});
		};
		this.buyProductReject = reject;

		const orderProduct = createPaymentResult.OrderProduct;
		FAPI.UI.showPayment(
			request.productNameHint ?? orderProduct.LocalizedProductName,
			request.productDescriptionHint ?? orderProduct.LocalizedProductDescription,
			orderProduct.Sku,
			orderProduct.Price!,
			null,
			createPaymentResult.Odnoklassniki!.ExtraAttributes,
			undefined,
			"true"
		);

		return promise;
	}

	async GetSocialInfo(): Promise<IFxIdSdkAdapterInfoSocial> {
		const rParams = FAPI.Util.getRequestParameters();

		const userInfo = await this.OkGetCurrentUser();
		const gender = this.MapOkGender(userInfo.gender);

		return Promise.resolve({
			social: FxIdDomainStoreEnumsSupportedWebPublishingPlatform.Odnoklassniki,
			paymentsAvailable: true,
			userId: rParams.logged_user_id!.toString(),
			firstName: userInfo.first_name,
			lastName: userInfo.last_name,
			photo: {
				width: 224,
				height: 224,
				url: userInfo.pic224x224
			},
			gender,
			capabilities: [
				EFxIdSdkAdapterInfoSocialCapability.FriendsList,
				EFxIdSdkAdapterInfoSocialCapability.FriendsInvite,
				EFxIdSdkAdapterInfoSocialCapability.OutgoingLinksForbidden,
				EFxIdSdkAdapterInfoSocialCapability.AdsAvailable
			]
		});
	}

	async Initialize(): Promise<void> {
		log.info("Initializing OK adapter");
		// Load the SDK's source Asynchronously

		await new Promise<void>((resolve) => {
			const s = document.createElement("script");
			s.type = "text/javascript";
			s.src = "//api.ok.ru/js/fapi5.js";
			s.addEventListener(
				"load",
				function (_e) {
					log.info("Script loaded");
					resolve();
				},
				false
			);
			const head = document.getElementsByTagName("head")[0];
			head.appendChild(s);

			log.info("Script added");
		});

		log.info("Initializing api");
		const rParams = FAPI.Util.getRequestParameters();

		// const displayName = rParams.user_name == null ? `#${rParams.logged_user_id}` : `${rParams.user_name} (#{})`
		const displayName = `#${rParams.logged_user_id}`;
		if (rParams.logged_user_id !== undefined) userStore.getState().updateUserId(rParams.logged_user_id);
		userStore.getState().updateDisplayName(displayName);

		await new Promise<void>((resolve, reject) => {
			FAPI.init(
				rParams.api_server!,
				rParams.apiconnection!,
				/*
				 * Первый параметр:
				 * функция, которая будет вызвана после успешной инициализации.
				 */
				() => {
					log.info("FAPI initialized");
					setInterval(() => FAPI.UI.getPageInfo(), this._PAGE_INFO_REFRESH_INTERVAL_MS);

					log.info(`Requesting page info from OK every ${this._PAGE_INFO_REFRESH_INTERVAL_MS}ms`);
					resolve();
					// здесь можно вызывать методы API
				},
				/*
				 * Второй параметр:
				 * функция, которая будет вызвана, если инициализация не удалась.
				 */
				function (error) {
					log.error("FAPI failed to initialize: %o", error);
					reject(error);
				}
			);
		});

		void this.LoadVideoAds();
	}

	LoadVideoAds() {
		FAPI.UI.loadAd();
	}

	StoreCurrency(): Promise<string | undefined> {
		return Promise.resolve(undefined);
	}

	async StatInitialize(request: IFxIdSdkAdapterStatInitializeRequest): Promise<void> {
		return Promise.resolve();
	}

	StatEvent(request: IFxIdSdkAdapterStatEventRequest): Promise<void> {
		return Promise.resolve(undefined);
	}

	override AdsIsVideoReady() {
		FAPI.UI.loadAd();
		return Promise.resolve(this._adAvailable);
	}

	override AdsShowVideo() {
		FAPI.UI.showLoadedAd();
		return Promise.resolve();
	}

	override async GetFriends(request: IFxIdSdkAdapterGetFriendsRequest): Promise<IFxIdSdkGetFriendsResult> {
		const okFriendsData = await this.OkFriendsGet();

		const result: IFxIdSdkGetFriendsResult = { friends: [] };

		for (const okFriendData of okFriendsData) {
			const gender = this.MapOkGender(okFriendData.gender);
			const friend: IFxIdSdkGetFriendsResultFriend = {
				uid: okFriendData.uid,
				firstName: okFriendData.first_name,
				lastName: okFriendData.last_name,
				gender,
				photo: {
					width: 224,
					height: 224,
					url: okFriendData.pic224x224
				}
			};

			result.friends.push(friend);
		}

		return result;
	}

	private OkFriendsGet() {
		return new Promise<IOkGetUserInfoResult[]>((resolve, reject) => {
			const GetOkData = async (data: unknown) => {
				try {
					// ok api limit
					const maxFriendsPerRequest = 100;

					const chunkedData = chunk(data as string[], maxFriendsPerRequest);

					let friendsData: IOkGetUserInfoResult[] = [];

					for (const chunkOfData of chunkedData) {
						const result = await this.OkGetUsersInfo(chunkOfData);
						friendsData = friendsData.concat(result);
					}

					resolve(friendsData);
				} catch (e) {
					reject(e);
					return;
				}
			};

			const callback_friends_get: OkApiCallback = (status, data, error) => {
				try {
					if (data != null) {
						void GetOkData(data);
						return;
					}
				} catch (e) {
					reject(e);
					return;
				}

				reject(new Error(JSON.stringify(error)));
			};

			FAPI.Client.call({ method: "friends.get" }, callback_friends_get);
		});
	}

	private OkGetUsersInfo(uids: string[]): Promise<IOkGetUserInfoResult[]> {
		return new Promise<IOkGetUserInfoResult[]>((resolve, reject) => {
			const callback_users_getInfo: OkApiCallback = function (status, data, error) {
				if (data != null) {
					resolve(data as IOkGetUserInfoResult[]);
					return;
				}

				reject(new Error(JSON.stringify(error)));
			};

			FAPI.Client.call(
				{
					method: "users.getInfo",
					fields: "uid,first_name,last_name,gender,PIC224X224",
					uids: uids
				},

				callback_users_getInfo
			);
		});
	}

	private OkGetCurrentUser(): Promise<IOkGetUserInfoResult> {
		return new Promise<IOkGetUserInfoResult>((resolve, reject) => {
			const callback_users_getInfo: OkApiCallback = function (status, data, error) {
				if (data != null) {
					resolve(data as IOkGetUserInfoResult);
					return;
				}

				reject(new Error(JSON.stringify(error)));
			};

			FAPI.Client.call(
				{
					method: "users.getCurrentUser",
					fields: "uid,first_name,last_name,gender,PIC224X224"
				},

				callback_users_getInfo
			);
		});
	}

	private MapOkGender(genderStr?: string) {
		let gender: EFxIdSdkGender = EFxIdSdkGender.Unspecified;
		if (genderStr == null) return gender;

		switch (genderStr.toLowerCase()) {
			case "m":
			case "male":
				gender = EFxIdSdkGender.Male;
				break;
			case "f":
			case "female":
				gender = EFxIdSdkGender.Female;
				break;
		}
		return gender;
	}

	override async InviteFriends(request: IFxIdSdkAdapterInviteFriendsRequest): Promise<IFxIdSdkInviteFriendsResult> {
		const friends = await this.GetFriends({});
		const { promise, resolve, reject } = deferred<string>();
		this.showNotificationResolve = resolve;
		this.showNotificationReject = reject;

		FAPI.UI.showNotification(
			request.inviteText,
			request.trackString,
			friends.friends.map((el) => el.uid).join(";")
		);

		const result = await promise;

		return {
			friends: result.split(",").map<IFxIdSdkGetFriendsResultFriend>((el) => {
				return { uid: el };
			})
		};
	}

	override AdsIsInterstitialReady() {
		window.FxIdSdk!.DispatchAdsInterstitialAvailable();
		return Promise.resolve(true);
	}

	override AdsShowInterstitial() {
		FAPI.UI.showAd();
		return Promise.resolve();
	}
}
